<template>
  <div class="store">
    <div class="title"></div>
    <div class="crumb"></div>
    <div class="content">
      <div class="actions">
        <div class="search">
          <a-input-search
            class="search-input"
            placeholder="请输入您要查找的内容"
            v-model="searchName"
            @search="onSearch"
          />
        </div>
        <div class="operation">
          <a-button
            class="add"
            type="primary"
            @click="showStoreTypeSelectionDialog"
            >添加新的数据存储</a-button
          >

          <a-button class="delete" type="danger" @click="showDelDialog"
            >删除选定的数据存储</a-button
          >
        </div>
      </div>
      <a-card class="table">
        <a-table
          :columns="columns"
          :data-source="showData"
          :row-selection="rowSelection"
        >
          <span slot="dataType" slot-scope="text">
            <div>{{ text | transDataTypeName }}</div>
          </span>
          <span slot="workspace" slot-scope="text, record">
            <div>{{ record.workspace }}</div>
          </span>
          <span slot="name" slot-scope="text, record">
            <a @click="showEditDialog(record)">{{ record.name }}</a>
          </span>
          <span slot="type" slot-scope="text">
            <div>{{ text | transDataTypeName }}</div>
          </span>
        </a-table>
      </a-card>
    </div>
    <store-type-selection
      :typeVisible.sync="typeVisible"
      :selectedType.sync="selectedType"
      :rasterInfoVisible.sync="rasterInfoVisible"
      :vectorInfoVisible.sync="vectorInfoVisible"
    ></store-type-selection>
    <raster-store-info
      :rasterInfoVisible.sync="rasterInfoVisible"
      :selectedType.sync="selectedType"
      :editData="editData"
      @refreshStore="refreshStore"
    ></raster-store-info>
    <vector-store-info
      :vectorInfoVisible.sync="vectorInfoVisible"
      :selectedType.sync="selectedType"
      :editData="editData"
      @refreshStore="refreshStore"
    ></vector-store-info>
    <a-modal
      v-model="delVisible"
      title="提示"
      ok-text="确认"
      cancel-text="取消"
      @ok="deleteSelectedStores"
      @cancel="hideDelDialog"
    >
      <p>删除这些数据存储后, 将同时删除由这些数据存储创建发布的所有图层, 确认操作吗？</p>
    </a-modal>
  </div>
</template>
<script>
import StoreTypeSelection from "./StoreTypeSelection";
import RasterStoreInfo from "./RasterStoreInfo";
import VectorStoreInfo from "./VectorStoreInfo";
import { getDefaultWorkspace } from "@/api/data/workspace.js";
import { getStoresTree, delDataStore, delCoverageStore } from "@/api/data/store.js";

const columns = [
  {
    title: "数据类型",
    dataIndex: "dataType",
    key: "dataType",
    scopedSlots: { customRender: "dataType" },
  },
  {
    title: "工作空间",
    dataIndex: "workspace",
    key: "workspace",
    scopedSlots: { customRender: "workspace" },
  },
  {
    title: "数据存储名称",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "类型",
    dataIndex: "type",
    key: "type",
    scopedSlots: { customRender: "type" },
  },
];

export default {
  data() {
    return {
      searchName: null,
      columns,
      data: [],
      showData: [],
      selectedRows: [],
      typeVisible: false,
      delVisible: false,
      rasterInfoVisible: false,
      vectorInfoVisible: false,
      selectedType: null,
      editData: null,
    };
  },
  methods: {
    onSearch() {
      if (this.searchName && this.searchName !== "") {
        this.showData = this.data.filter((p) => {
          return (
            p.dataType.indexOf(this.searchName) !== -1 ||
            p.workspace.indexOf(this.searchName) !== -1 ||
            p.name.indexOf(this.searchName) !== -1 ||
            p.type.indexOf(this.searchName) !== -1
          );
        });
      } else {
        this.refreshStore();
      }
    },
    showDelDialog() {
      if (this.selectedRows.length == 0) {
        this.$message.info("未选择任何数据存储");
      } else {
        this.delVisible = true;
      }
    },
    hideDelDialog() {
      this.delVisible = false;
    },
    showStoreTypeSelectionDialog() {
      getDefaultWorkspace().then((res) => {
        if (res.status == 404) {
          this.$message.error("请先至少创建一个工作区");
        } else {
          this.editData = null;
          this.typeVisible = true;
        }
      });
    },
    showEditDialog(data) {
      this.editData = data;
      if (data.dataType == "RASTER") {
        this.rasterInfoVisible = true;
      } else if (data.dataType == "VECTOR") {
        this.vectorInfoVisible = true;
      }
    },
    deleteSelectedStores() {
      this.selectedRows.map((item) => {
          if (item.dataType == "VECTOR") {
            delDataStore(item.workspace, item.name).then((res) => {
              if (res.status == 200) {
                this.$message.success(`已删除数据存储${item.name}`);
              } else if (res.status == 403) {
                this.$message.error();
                `已删除数据存储${item.name}`;
              }
              this.refreshStore();
            });
          } else if (item.dataType == "RASTER") {
            delCoverageStore(item.workspace, item.name).then((res) => {
              if (res.status == 200) {
                this.$message.success(`已删除数据存储${item.name}`);
              }
              this.refreshStore();
            });
          } else {
            this.$message.error(`${item.name}类型为${item.dataType}删除失败`);
          }
        });
        this.hideDelDialog();
    },
    refreshStore() {
      getStoresTree().then((res) => {
        this.data = res.data.map((item) => {
          item.key = item.id;
          if(item.available==null){
            item.type = item.type+'(文件损坏请重新上传)';
          }
          return item;
        });
        this.showData = this.data;
      });
    },
  },
  components: {
    StoreTypeSelection,
    RasterStoreInfo,
    VectorStoreInfo,
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows;
        },
      };
    },
  },
  mounted() {
    this.refreshStore();
    setTimeout(()=>{
      if (this.$route.query.add) {
        this.showStoreTypeSelectionDialog();
      }
    }, 300);
  },
};
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}
.search-input {
  width: 505px;
  height: 48px;
  background: #ffffff;
  border-radius: 24px;
}

.add,
.delete {
  width: 163px;
  height: 48px;
  border-radius: 4px;
  margin-right: 19px;
}
</style>
<style>
.store .search-input .ant-input{
  width: 505px;
  height: 48px;
  background: #ffffff;
  border-radius: 24px;
}

.store .search-input .ant-input-suffix{
  font-size: 18px;
}
</style>