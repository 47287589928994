import http from '@/config/axios.config'

// 得到工作区首页信息
export function getWorkspaces() {
    return http({
        url: `/rest/betaGISUI/workspaces/tree`,
        method: "get",
    })
}

// 得到工作区首页信息
export function getDefaultWorkspace() {
    return http({
        url: `/rest/workspaces/default`,
        method: "get",
    })
}

// 新建工作区
export function newWSByWS(data, defaultWS) {
    return http({
        url: `/rest/workspaces`,
        method: 'post',
        data: data,
        params: {
            default: defaultWS
        }
    })
}

// 设置默认工作区
export function setDefaultWorkspace(workspaceName) {
    return http({
        url: `/rest/betaGISUI/workspaces/default/${workspaceName}`,
        method: 'post',
    })
}

// 修改工作区属性
export function putWorkspace(workspaceName, data) {
    return http({
        url: `/rest/workspaces/${workspaceName}`,
        method: 'put',
        data: data
    })
}

// 删除工作区
export function delWorkspace(workspaceName) {
    return http({
        url: `/rest/workspaces/${workspaceName}.json?recurse=true`,
        method: 'delete',
    })
}