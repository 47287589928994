<template>
  <a-modal
    :visible="rasterInfoVisible"
    @ok="createOrUpdateRasterStore"
    @cancel="close"
    okText="确认"
    cancelText="取消"
  >
    <a-form :form="form">
      <h2>存储库的基本信息</h2>
      <a-form-item label="工作空间">
        <a-select
          v-model="rasterStoreInfo.workspace"
          style="
            width: 166px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #dcdcdc;
            border-radius: 4px;
          "
        >
          <a-select-option
            v-for="ws in workspaces"
            :key="ws.name"
            :value="ws.name"
            >{{ ws.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="数据源名称">
        <a-input :maxLength="30" v-decorator="['name', ValidateRules.name]" />
      </a-form-item>
      <a-form-item label="说明">
        <a-input v-model="rasterStoreInfo.description" />
      </a-form-item>
      <h2>连接参数</h2>
      <a-form-item label="文件">
        <div>{{ rasterStoreInfo.url }}</div>
        <a-upload accept=".tif,.gpkg" name="file" @change="changeFile">
          <a-button>
            <a-icon type="upload" />
            {{ rasterStoreInfo.url == "" ? "上传文件" : "重新上传文件" }}
          </a-button>
        </a-upload>
        <p>{{ help }}</p>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { getWorkspaces, getDefaultWorkspace } from "@/api/data/workspace.js";
import {
  getStoreByName,
  newCoverageStore,
  putCoverageStore,
  uploadFile,
} from "@/api/data/store.js";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      workspaces: [],
      defaultWorkspace: "",
      rasterStoreInfo: {
        workspace: "",
        description: "",
        url: "",
      },
      ValidateRules: {
        name: { rules: [{ required: true, message: "请输入工作空间名称" }] },
      },
      help: "",
      file: null,
      confirmLoading: false,
      source: null,
    };
  },
  props: ["rasterInfoVisible", "selectedType", "editData"],
  methods: {
    changeFile(info) {
      this.help = "";
      let isZip = /(.tif)$/.test(info.file.name);
      let isGpkg = /(.gpkg)$/.test(info.file.name);
      if (!isZip && !isGpkg) {
        this.help = "请选择.tif或.gpkg文件";
        return;
      }
      if (info.fileList.length > 0) {
        this.file = info.fileList[0].originFileObj;
      } else {
        this.file = null;
      }
    },
    // 覆盖请求
    customRequest() {},
    createOrUpdateRasterStore() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.file) {
            this.confirmLoading = true;
            this.source = this.$http.CancelToken.source();
            let fd = new FormData();
            fd.append("file", this.file);
            uploadFile({
              data: fd,
              source: this.source,
              progressCallback: (num) => {
                console.log(num);
              },
            }).then((res) => {
              this.confirmLoading = false;
              if (res.status == 200 && res.data) {
                console.log(res.data);
                let data = {
                  coverageStore: {
                    name: values.name,
                    workspace: this.rasterStoreInfo.workspace,
                    description: this.rasterStoreInfo.description,
                    enabled: true,
                    type: this.selectedType,
                    url: res.data,
                  },
                };
                console.log(data);
                if (this.editData) {
                  putCoverageStore(this.rasterStoreInfo.workspace, data).then(
                    (res) => {
                      if (res.status == 200) {
                        this.$message.success("更新数据存储成功");
                        this.$emit("refreshStore");
                        this.close();
                      }
                    }
                  );
                } else {
                  newCoverageStore(this.rasterStoreInfo.workspace, data).then(
                    (res) => {
                      if (res.status == 201) {
                        this.$message.success("创建数据存储成功");
                        this.$emit("refreshStore");
                        this.close();
                      }
                    }
                  );
                }
              }
            });
          } else {
            if (this.editData) {
              this.updateStore(
                this.getStoreBody(values.name, this.vectorStoreInfo.url)
              );
            } else {
              this.help = "请选择.zip文件";
            }
          }
        }
      });
    },
    close() {
      this.rasterStoreInfo = {
        name: "",
        workspace: "",
        description: "",
        url: "",
      };
      this.form.resetFields();
      this.setDefaultWorkspace();
      this.$emit("update:rasterInfoVisible", false);
      this.$emit("update:selectedType", null);
    },
    setDefaultWorkspace() {
      getDefaultWorkspace().then((res) => {
        if (res.status == 200) {
          this.rasterStoreInfo.workspace = res.data.workspace.name;
        }
      });
    },
  },
  watch: {
    rasterInfoVisible() {
      if (this.rasterInfoVisible && this.editData) {
        getStoreByName(this.editData.name).then((res) => {
          this.rasterStoreInfo = res.data;
          this.$nextTick(() => {
            this.form.setFieldsValue({
              name: this.rasterStoreInfo.name,
              url: this.rasterStoreInfo.url,
            });
          });
        });
      }
    },
  },
  mounted() {
    getWorkspaces().then((res) => {
      this.workspaces = res.data;
    });
    this.setDefaultWorkspace();
  },
};
</script>
