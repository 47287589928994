import http from '@/config/axios.config'

// 得到数据存储首页信息
export function getStoresTree() {
  return http({
    url: `/rest/betaGISUI/stores/tree`,
    method: "get",
  })
}

// 得到单个数据存储信息
export function getStoreByName(storeName) {
  return http({
    url: `/rest/betaGISUI/stores/store/${storeName}`,
    method: "get",
  })
}

// 得到所有支持的数据类型
export function getAvailableStores() {
  return http({
    url: `/rest/betaGISUI/stores/available`,
    method: "get",
  })
}

// 新增矢量数据存储
export function newDataStore(workspaceName, data) {
  return http({
    url: `/rest/workspaces/${workspaceName}/datastores`,
    method: "post",
    data: data,
  })
}
// 新增栅格数据存储
export function newCoverageStore(workspaceName, data) {
  return http({
    url: `/rest/workspaces/${workspaceName}/coveragestores`,
    method: "post",
    data: data,
  })
}
// 修改矢量数据存储
export function putDataStore(workspaceName, storeName, data) {
  return http({
    url: `/rest/workspaces/${workspaceName}/datastores/${storeName}`,
    method: "put",
    data: data,
  })
}
// 修改栅格数据存储
export function putCoverageStore(workspaceName, storeName, data) {
  return http({
    url: `/rest/workspaces/${workspaceName}/coveragestores/${storeName}`,
    method: "put",
    data: data,
  })
}
// 删除矢量数据存储
export function delDataStore(workspaceName, storeName) {
  return http({
    url: `/rest/workspaces/${workspaceName}/datastores/${storeName}.json?recurse=true`,
    method: "delete",
  })
}
// 删除栅格数据存储
export function delCoverageStore(workspaceName, storeName) {
  return http({
    url: `/rest/workspaces/${workspaceName}/coveragestores/${storeName}.json?recurse=true`,
    method: "delete",
  })
}

// 上传矢量文件
export function uploadFile(options) {
  return http({
    url: `/rest/betaGISUI/stores/upload`,
    method: "post",
    data: options.data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // "X-Auth-Token":window.sessionStorage.getItem("token")
    },
    cancelToken: options.source.token,
    onUploadProgress(progressEvent) {
      return options.progressCallback(progressEvent);
    },
  })
}