<template>
  <a-modal
    :visible="vectorInfoVisible"
    :confirmLoading="confirmLoading"
    @ok="createOrUpdateVectorStore"
    @cancel="close"
    okText="确认"
    cancelText="取消"
  >
    <a-form :form="form">
      <h2>存储库的基本信息</h2>
      <a-form-item label="工作空间">
        <a-select
          v-model="vectorStoreInfo.workspace"
          style="
            width: 166px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #dcdcdc;
            border-radius: 4px;
          "
        >
          <a-select-option
            v-for="ws in workspaces"
            :key="ws.name"
            :value="ws.name"
            >{{ ws.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="数据源名称">
        <a-input :maxLength="30" v-decorator="['name', ValidateRules.name]" />
      </a-form-item>
      <a-form-item label="说明">
        <a-input v-model="vectorStoreInfo.description" />
      </a-form-item>
      <h2>连接参数</h2>
      <a-form-item label="文件" v-show="showFile">
        <div>{{ vectorStoreInfo.url }}</div>
        <a-upload accept=".zip,.gpkg" name="file" @change="changeFile">
          <a-button>
            <a-icon type="upload" />
            {{ vectorStoreInfo.url == "" ? "上传文件" : "重新上传文件" }}
          </a-button>
        </a-upload>
        <p>{{ help }}</p>
      </a-form-item>
      <a-form-item label="字符集编码" v-show="showCharset">
        <a-select v-model="vectorStoreInfo.charset">
          <a-select-option
            v-for="charset in charsetArr"
            :key="charset"
            :value="charset"
            >{{ charset }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item v-show="showDBConfig">
        主机地址: <a-input v-model="vectorStoreInfo.host" /> 端口:
        <a-input v-model="vectorStoreInfo.port" /> 数据库:
        <a-input v-model="vectorStoreInfo.database" /> 用户名:
        <a-input v-model="vectorStoreInfo.user" /> 密码:
        <a-input-password v-model="vectorStoreInfo.passwd" />
      </a-form-item>
      <a-form-item v-show="showESConfig">
        主机地址: <a-input v-model="vectorStoreInfo.host" /> 端口:
        <a-input v-model="vectorStoreInfo.port" /> 索引名称:
        <a-input v-model="vectorStoreInfo.es_index_name" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import charsetArr from "@/assets/charset.js";
import { getWorkspaces, getDefaultWorkspace } from "@/api/data/workspace.js";
import {
  getStoreByName,
  newDataStore,
  putDataStore,
  uploadFile,
} from "@/api/data/store.js";

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      charsetArr,
      workspaces: [],
      defaultWorkspace: "",
      vectorStoreInfo: {
        name: "",
        workspace: "",
        description: "",
        url: "",
        charset: "ISO-8859-1",
        host: "",
        port: "",
        database: "",
        user: "",
        passwd: "",
        es_index_name: "",
      },
      ValidateRules: {
        name: { rules: [{ required: true, message: "请输入工作空间名称" }] },
        url: { rules: [{ required: true, message: "请选择文件" }] },
      },
      help: "",
      file: null,
      confirmLoading: false,
      source: null,
      showFile: false,
      showCharset: false,
      showDBConfig: false,
      showESConfig: false,
    };
  },
  props: ["vectorInfoVisible", "selectedType", "editData"],
  methods: {
    changeFile(info) {
      this.help = "";
      let isZip = /(.zip)$/.test(info.file.name);
      let isGpkg = /(.gpkg)$/.test(info.file.name);
      if (!isZip && !isGpkg) {
        this.help = "请选择.zip或.gpkg文件";
        return;
      }
      if (info.fileList.length > 0) {
        this.file = info.fileList[0].originFileObj;
      } else {
        this.file = null;
      }
    },
    // 覆盖请求
    customRequest() {},
    createOrUpdateVectorStore() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.showFile) {
            // check文件
            if (this.file) {
              this.confirmLoading = true;
              this.source = this.$http.CancelToken.source();
              let fd = new FormData();
              fd.append("file", this.file);
              uploadFile({
                data: fd,
                source: this.source,
                progressCallback: (num) => {
                  console.log(num);
                },
              }).then((res) => {
                this.confirmLoading = false;
                if (res.status == 200 && res.data) {
                  let data = this.getStoreBody(values.name, res.data);
                  if (this.editData) {
                    this.updateStore(data);
                  } else {
                    this.createStore(data);
                  }
                }
              });
            } else {
              if (this.editData) {
                this.updateStore(
                  this.getStoreBody(values.name, this.vectorStoreInfo.url)
                );
              } else {
                this.help = "请选择.zip文件";
              }
            }
          } else if (this.showDBConfig || this.showESConfig) {
            let data = this.getStoreBody(values.name, null);
            if (this.editData) {
              this.updateStore(data);
            } else {
              this.createStore(data);
            }
          }
        }
      });
    },
    updateStore(data) {
      putDataStore(
        this.vectorStoreInfo.workspace,
        this.editData.name,
        data
      ).then((res) => {
        if (res.status == 200) {
          this.$message.success("更新数据存储成功");
          this.close();
        }
      });
    },
    createStore(data) {
      newDataStore(this.vectorStoreInfo.workspace, data).then((res) => {
        if (res.status == 201) {
          this.$message.success("创建数据存储成功");
          this.close();
        }
      });
    },
    getStoreBody(name, url) {
      let entryValue = [];
      if (this.selectedType == "Shapefile") {
        entryValue = [
          { "@key": "url", $: `file://` + url },
          { "@key": "charset", $: this.vectorStoreInfo.charset },
          { "@key": "create spatial index", $: "true" },
          { "@key": "cache and reuse memory maps", $: "true" },
        ];
      } else if (this.selectedType == "GeoPackage") {
        entryValue = [
          { "@key": "database", $: url },
          { "@key": "dbtype", $: "geopkg" },
        ];
      } else if (this.selectedType == "PostGIS") {
        entryValue = [
          { "@key": "host", $: this.vectorStoreInfo.host },
          { "@key": "port", $: this.vectorStoreInfo.port },
          { "@key": "database", $: this.vectorStoreInfo.database },
          { "@key": "user", $: this.vectorStoreInfo.user },
          { "@key": "passwd", $: this.vectorStoreInfo.passwd },
          { "@key": "dbtype", $: "postgis" },
        ];
      } else if (this.selectedType == "Elasticsearch") {
        entryValue = [
          { "@key": "elasticsearch_host", $: this.vectorStoreInfo.host },
          { "@key": "elasticsearch_port", $: this.vectorStoreInfo.port },
          { "@key": "index_name", $: this.vectorStoreInfo.es_index_name },
          { "@key": "runas_geoserver_user", $: "false" },
          { "@key": "ssl_reject_unauthorized", $: "true" },
          { "@key": "source_filtering_enabled", $: "false" },
          { "@key": "scroll_enabled", $: "false" },
          { "@key": "scroll_size", $: "20" },
          { "@key": "scroll_time", $: "120" },
          { "@key": "array_encoding", $: "JSON" },
          { "@key": "default_max_features", $: "100" },
          { "@key": "grid_size", $: "10000" },
          { "@key": "grid_threshold", $: "0.05" },
        ];
      }
      let data = {
        dataStore: {
          name: name,
          description: this.vectorStoreInfo.description,
          connectionParameters: {
            entry: entryValue,
          },
        },
      };
      return data;
    },
    close() {
      this.$emit("refreshStore");
      this.$emit("update:vectorInfoVisible", false);
      this.$emit("update:selectedType", null);
      this.vectorStoreInfo = {
        name: "",
        workspace: "",
        description: "",
        url: "",
        charset: "UTF-8",
        host: "",
        port: "",
        database: "",
        user: "",
        passwd: "",
        es_index_name: "",
      };
      this.form.resetFields();
      this.help = "";
      this.showFile = false;
      this.file = null;
      this.fileList = [];
      this.confirmLoading = false;
      this.showCharset = false;
      this.showDBConfig = false;
      this.showESConfig = false;
      if (this.source) {
        //我先判断soucre是否存在，因为如果我打开弹框不作任何操作，点击取消按钮没有这一层判断的话，that.source.cancel('取消上传');会报错。
        this.source.cancel("取消请求"); //"取消上传"这几个字，会在上面catch（）的error中输出的，可以console看一下。
        // this.percentage = 0;
        this.source = null;
      }
      this.setDefaultWorkspace();
    },
    setShowType(type) {
      this.showFile =
        type == "Shapefile" ||
        type == "Directory of spatial files (shapefiles)" ||
        type == "GeoPackage";
      this.showCharset =
        type == "Shapefile" ||
        type == "Directory of spatial files (shapefiles)";
      this.showDBConfig = type == "PostGIS";
      this.showESConfig = type == "Elasticsearch";
    },
    setDefaultWorkspace() {
      getDefaultWorkspace().then((res) => {
        if (res.status == 200) {
          this.vectorStoreInfo.workspace = res.data.workspace.name;
        }
      });
    },
  },
  watch: {
    vectorInfoVisible() {
      if (this.vectorInfoVisible) {
        if (this.editData) {
          this.setShowType(this.editData.type);
          getStoreByName(this.editData.name).then((res) => {
            this.vectorStoreInfo = res.data;
            if (
              this.editData.type == "Directory of spatial files (shapefiles)"
            ) {
              this.vectorStoreInfo.url = res.data.param.url;
              this.vectorStoreInfo.charset = res.data.param.charset;
            } else if (this.editData.type == "GeoPackage") {
              this.vectorStoreInfo.url = res.data.param.database;
            } else if (this.showDBConfig) {
              this.vectorStoreInfo.host = res.data.param.host;
              this.vectorStoreInfo.port = res.data.param.port;
              this.vectorStoreInfo.database = res.data.param.database;
              this.vectorStoreInfo.user = res.data.param.user;
              this.vectorStoreInfo.passwd = res.data.param.passwd;
            } else if (this.showESConfig) {
              this.vectorStoreInfo.host = res.data.param.elasticsearch_host;
              this.vectorStoreInfo.port = res.data.param.elasticsearch_port;
              this.vectorStoreInfo.es_index_name = res.data.param.index_name;
            }
            this.$nextTick(() => {
              this.form.setFieldsValue({
                name: this.vectorStoreInfo.name,
              });
            });
          });
        } else {
          this.setShowType(this.selectedType);
        }
      }
    },
  },
  mounted() {
    getWorkspaces().then((res) => {
      this.workspaces = res.data;
    });
    this.setDefaultWorkspace();
  },
};
</script>
