<template>
  <a-modal
    :visible="typeVisible"
    @ok="cancel"
    @cancel="cancel"
    okText="确认"
    cancelText="取消"
  >
    <div class="title">新建数据源</div>
    <div class="description">选择你要配置的数据源的类型</div>
    <div class="vectorDataSource">
      <h2>矢量数据源</h2>
      <a-list item-layout="horizontal" :data-source="availableDataStores">
        <a-list-item slot="renderItem" slot-scope="item">
          <div class="item">
            <a @click="setType(item.name, `VECTOR`)">{{ item.name }}</a>
            <div>{{ item.description }}</div>
          </div>
        </a-list-item>
      </a-list>
    </div>
    <div class="rasterDataSource">
      <h2>栅格数据源</h2>
      <a-list item-layout="horizontal" :data-source="availableCoverageStores">
        <a-list-item slot="renderItem" slot-scope="item">
          <div class="item">
            <a @click="setType(item.name, `RASTER`)">{{ item.name }}</a>
            <div>{{ item.description }}</div>
          </div>
        </a-list-item>
      </a-list>
    </div>
    <!-- <div class="otherDataSource">
      <h2>其他数据源</h2>
      <div></div>
    </div> -->
  </a-modal>
</template>
<script>
import { getAvailableStores } from "@/api/data/store.js";
export default {
  data() {
    return {
      availableDataStores: [],
      availableCoverageStores: [],
    };
  },
  props: [
    "typeVisible",
    "selectedType",
    "rasterInfoVisible",
    "vectorInfoVisible",
  ],
  methods: {
    setType(type, classType) {
      this.$emit("update:selectedType", type);
      this.$emit("update:typeVisible", false);
      if (classType == `RASTER`) {
        this.$emit("update:rasterInfoVisible", true);
      } else if (classType == `VECTOR`) {
        this.$emit("update:vectorInfoVisible", true);
      }
    },
    cancel() {
      this.$emit("update:typeVisible", false);
    },
  },
  mounted() {
    getAvailableStores().then((res) => {
      this.availableDataStores = res.data.availableDataStores;
      this.availableCoverageStores = res.data.availableCoverageStores;
    });
  },
};
</script>
